$(function () {
    var indeks = parseFloat($('#calendar-container').data('index'));

    $('#calendar-container').on('click', '.month-add', function () {
        changeMonth('add');
    })

    $('#calendar-container').on('click', '.month-sub', function () {
        changeMonth('sub');
    })

    function changeMonth(direction) {
        var url = $('#calendar-container').data('calendarUrl');
        if (direction == 'add') {
            indeks = indeks + 1;
        } else if (direction == 'sub') {
            indeks = indeks - 1;
        }
        $.ajax({
            url: url,
            method: 'POST',
            data: {month: indeks > 0 ? '+' + indeks : indeks}
        }).done(function (html) {
            $("#calendar").replaceWith(html);
            $('[data-toggle="popover"]').popover();
        })
//        $('#calendar-container').attr('data-index', indeks);


        console.log(indeks);
    }
});