import 'popper.js';
import $ from'jquery';
import 'bootstrap';
import 'particles.js';
import './particles-start.js';
import './paypal.js';
import './calendar.js';
//import filterizr from 'filterizr/dist/jquery.filterizr.min.js';
import 'magnific-popup';
// import 'summernote';
// import $ from 'filterizr';


$(function () {
    console.log('v5');

//    $("#topVideo").get(0).play();
    $('.popup-link').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });
    $('.toast').toast();
    $('.dropdown-item').click(function (e) {
        e.stopPropagation();
    });
    $('[data-toggle="popover"]').popover();

    $('.action').click(function () {
        $('.toast').toast('show');
    });

/*
    $('#kurs_program, #kurs_uwagi, #kurs_opis, #prowadzacy_opis, #prowadzacy_opis_krotki').summernote({
        height: 200,
        toolbar: [
            // [groupName, [list of button]]
            [
                'style',
                [
                    'style',
                    'bold',
                    'italic',
                    'underline',
                    'clear'
                ]
            ],
            [
                'font',
                [
                    'strikethrough',
                    'superscript',
                    'subscript'
                ]
            ],
            [
                'para',
                [
                    'ul',
                    'ol',
                    'paragraph'
                ]
            ],
            [
                'insert',
                [
                    'link'
                ]
            ],
            [
                'view',
                [
                    'codeview'
                ]
            ],
        ]
    });
    */
});