$(function () {
    var currency;
    var amount;
    var date;
    var zawod;
    var rezerwa;

/*
    var len = $('script').filter(function () {
        return ($(this).attr('src') == 'https://www.paypal.com/sdk/js?client-id=ATwQz_GKYlyhaqQZf_RfqA-LwdXc7LVdCVq3zcylHhAyPzOH6HFLEE4_PCr1xi3P5362JD46AD_Uep-L&currency=PLN' ||
                $(this).attr('src') == 'https://www.paypal.com/sdk/js?client-id=ATMV7bTMEadet0knks73N8F68YMxB_Q9bszlEa4z2AxCed4euqXFqiTckVCpLulj7CNSNZvsZ1WjkGYF&currency=PLN' ||
                $(this).attr('src') == 'https://www.paypal.com/sdk/js?client-id=AX90beBNKC7bLC58Trp_UxDImmjyP6sl1lPweb5DvNI0Ci_HyM8dT4ot8TFcVaJJmxtlkSYCZ2pcbOIZ&currency=PLN' ||
                $(this).attr('src') == 'https://www.paypal.com/sdk/js?client-id=AWPFdkrVuVgpJJtacUkibuadGsomF3PdlnfrVili85PhjoW_O4FVqiTiVaSw2o_sBcApGvJVsBiOLOE7&currency=PLN');
    }).length;

    if (len) {
        clear();
        paypal.Buttons({
            createOrder: function (data, actions) {
                // Set up the transaction
                return actions.order.create({
                    purchase_units: [
                        {
                            amount: {
                                currency_code: currency,
                                value: amount
                            }
                        }
                    ]
                });
            },
            onApprove: function (data, actions) {
                // Capture the funds from the transaction
                $('#paymentModal').modal('show');
                return actions.order.capture().then(function (details) {
                    console.log('success', details);
                    $('#rejestracja_platnosc_status').val(details.status);
                    $('#rejestracja_platnosc_id').val(details.id);
                    $('#rejestracja_platnik_info').val(details.payer.name.given_name + ' ' + details.payer.name.surname + ', ' + details.payer.email_address);
                    $('#payment-info').html('Płatność zaakceptowana<br>Nie możesz już zmianić daty kursu ani zawodu!');
                    $('#paypal-button-container').addClass('disabled');
                    $('[name="rejestracja[daty_kursu]"]').click(function () {
                        return false;
                    });
                    $('[name="rejestracja[zawod]"]').click(function () {
                        return false;
                    });
                });
            },
            onError: function (err) {
                $('#rejestracja_platnosc_status').val('ERROR');
                console.log('error');
                console.log(err);
            },
            onCancel: function (data) {
                $('#rejestracja_platnosc_status').val('CANCELED');
                console.log('cancel');
                console.log(data);
            }
        }).render('#paypal-button-container');


*/
    $('[name="rejestracja[daty_kursu]"]').change(function () {
            console.log('[daty_kursu] change')
            date = $(this).val();
            rezerwa = $(this).data('rezerwa');
            setValues();
        });
        $('[name="rejestracja[zawod]"]').change(function () {
            console.log('[zawod] change')
            zawod = $(this).val();
            setValues();
        });
    
    // }
    function setValues() {
//        var url = $('#paypal-button-container').data('priceUrl');
        var url = $('#rejestracja').data('priceUrl');
        if (date != undefined && zawod != undefined) {
            $.getJSON(url,
                    {
                        date: date,
                        zawod: zawod,
                    }, function (data) {
                currency = data.currency;
                amount = data.price;
                rezerwa = data.rezerwa;
                if (!rezerwa) {
                    $('#paypal-button-container').removeClass('disabled');
                    $('#payment-info').text('Całkowity koszt kursu: ' + amount + ' ' + currency);
                }
                $('#rejestracja_submit').attr('disabled', false);
                $('#rejestracja_cena').val(amount);
                $('#rejestracja_waluta').val(currency);
            }
            );
        }
        console.log('kurs_data: ', date);
        console.log('zawod: ', zawod);
        console.log('url: ', url);
        console.log('rezerwa: ', rezerwa);
    }

    function clear() {
        currency = undefined;
        amount = undefined;
        date = undefined;
        zawod = undefined;
        $('[name="rejestracja[zawod]"]').prop('checked', false);
        $('[name="rejestracja[kurs_data]"]').prop('checked', false);
        $('#rejestracja_platnosc_status').val('NONE');
        $('#rejestracja_platnosc_id').val(undefined);
        $('#rejestracja_platnik_info').val(undefined);
    }
});
